$primaryBrand: #1B87C5;
$secondaryBrand: #0055A3;

$primaryAccent: #50C0FF;
$secondaryAccent: #06A5FE;

$lightestGray: #c4c4c4;
$white: #ffffff;

$text: #666666;
$subtext: #888888;

$primaryGradient: linear-gradient(to right, $primaryBrand, $secondaryBrand);

.text-maintext {
  color: $text;
}

.text-subtext {
  color: $subtext;
}