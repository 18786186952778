.container {
  max-width: 1000px;
  padding: 0 20px;
  margin: 0 auto;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.justify-center {
  justify-content: center;
  justify-items: center;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
  align-content: center;
}

.items-baseline {
  align-items: baseline;
}

.w-full {
  width: 100%;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-6 {
  margin-right: 1.5rem;
}