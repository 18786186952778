.text-sm	{
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-base	{
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg	{
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl	{
  font-size: 1.25rem;
  line-height: 1.75rem;
}