@import 'colors';
@import 'layout';
@import 'size';

@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@400;500;700;900&display=swap');

html, body {
  margin: 0;
  padding: 0;
  font-family: 'Alegreya Sans', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", 'Yu Gothic', sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: $text;
}

* {
  box-sizing: border-box;
}

header > .container {
  padding: 10px 20px;
  height: 54px;
  align-items: center;
  justify-content: space-between;
}

a {
  text-decoration: none;
  text-transform: uppercase;
  color: $secondaryBrand;
  border-bottom: 1px solid transparent;
  font-size: 14px;
  letter-spacing: 0.1em;
  font-weight: 500;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    display: block;
    height: 1px;
    width: 0;
    right: 0;
    background-color: $primaryBrand;
    transition: width 250ms;
  }

  &:hover:after {
    left: 0;
    width: 100%;
  }
}

a.no-decoration {
  &:after {
    display: none;
  }
}

.download-badges {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.badge {
  height: 4rem;
  display: inline-block;

  img {
    height: 100%;
  }

  &.app-store {
    padding: 8.5px 10px;
  }
}

nav {
  display: flex;
  column-gap: 26px;
}

h1 {
  font-size: 36px;
  font-weight: 900;
  background: $primaryGradient;
  color: $secondaryBrand;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h2 {
  color: $secondaryBrand;
  font-weight: 800;
  font-size: 24px;
  margin-bottom: 10px;
  letter-spacing: .05em;
}

p {
  margin: 0;
  font-size: 18px;
  letter-spacing: 0.05em;
}

#about {
  padding-top: 60px;
  padding-bottom: 10px;
  background-image: url('../assets/Hero-Bg.png');
  background-repeat: no-repeat;
  background-size: 400px;
  background-position: calc(50% + 450px) 100px;
}

.hero-text {
  display: flex;
  align-items: center;
  padding-right: 40px;
  position: relative;

  .text-decoration {
    position: absolute;
    left: -140px;
    width: 180px;
    height: 180px;
    background: url('../assets/Pattern.png');
    background-position: 100%;
    background-repeat: no-repeat;
    background-clip: padding-box;
    background-size: cover;
  }

  h1 {
    margin-left: 60px;
  }
}

.hero-cta {
  margin-left: 60px;

  a {
    text-transform: uppercase;
    text-decoration: none;
    color: $secondaryBrand;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.05em;
  }
}

.hero-image {
  pointer-events: none;
  margin-right: 80px;
  height: 400px;
}

.services {
  color: $white;
  padding-top: 80px;
  padding-bottom: 60px;
  margin-top: -40px;

  background: $primaryGradient;
  background-color: $secondaryBrand;

  h2 {
    color: $white;
    margin-bottom: 30px;
  }
}

.service-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;

  .service-item {
    background-color: $white;
    padding: 20px;
    border-radius: 20px;

    h3 {
      color: $secondaryBrand;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 5px;
    }

    p {
      color: $text;
      margin-top: 0;
      letter-spacing: normal;
    }
  }
}

#supported-provider {
  margin-top: 2rem;

  .provider-icon-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    column-gap: 10px;
    row-gap: 20px;
    margin-top: 20px;
    margin-bottom: 40px;
  
    .provider-icon {
      max-height: 90px;
    }
  }
}

#mobile-app {
  background: url('../assets/App-Bg.png');
  background-repeat: no-repeat;
  background-size: 600px 600px;
  background-position: calc(50% + 500px) 0;

  .promo-text {
    margin-right: 30px;
  }

  .app-image {
    margin-top: 60px;
    height: 280px;
    align-self: flex-end;
    margin-left: 20px;
    margin-right: 20px;
  }
}

#contact-us {
  .name {
    font-size: 20px;
    color: $secondaryBrand;
    font-weight: bold;
  }

  .section-title {
    color: $subtext;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.05em;
  }

  .contact-us-content {
    display: grid;
    grid-template-columns: repeat(2, 50%);

    .map {
      iframe {
        width: 100%;
      }
    } 

    .info {
      margin-left: 1rem;
    }
  }
}

@media screen and (max-width: 768px) {
  header {    
    .container {
      height: auto;
      flex-direction: column;
      align-items: flex-start;
    }

    #logo {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  #about {
    background-position: calc(50% + 200px) 400px;

    .container {
      flex-direction: column;
    }

    .hero-cta {
      margin-bottom: 60px;
    }
  }

  .service-grid {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
  }

  #mobile-app {
    background-position: calc(100vw - 300px) 100px;

    .container {
      flex-direction: column;

      .promo-text {
        margin-right: 0;
      }

      & > picture {
        display: flex;
        align-self: center;
      }

      .app-image {
        align-self: center;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }

  #contact-us {
    .contact-us-content {
      grid-template-columns: 100%;

      .map {
        height: 100%;
        grid-row: 2;

        iframe {
          width: 100%;
        }
      }

      .info {
        margin-left: 0;
        margin-bottom: 1rem;
      }
    }
  }
}

@media screen and (max-width: 540px) {
  nav {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
    row-gap: 10px;
  }

  .service-grid {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 480px) {
  #supported-provider {
    .provider-icon-list {
      flex-direction: column;
  
      picture, .provider-icon {
        align-self: center;
      }
    }
  }

  #contact-us {
    .contact-info {
      flex-direction: column;

      & > div {
        margin-top: 1rem;
      }

      & > div:first-child {
        margin-top: 0;
      }
    }
  }
}

footer {
  text-align: center;
  font-size: 12px;
  color: $subtext;
  border-top: 1px solid $lightestGray;
  padding: 18px 15px;
}